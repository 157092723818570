import React from 'react';

import Layout from '../components/Layout';
import BlogRoll from '../components/BlogRoll';

const IndexPage = () => {
  return (
    <Layout title="Home">
      <BlogRoll />
    </Layout>
  );
};

export default IndexPage;
